import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const Page = ({ data }) => {
	const siteMetadata = useSiteMetadata()

	return  (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <MDXRenderer f={data.mdx.frontmatter} m={siteMetadata}>
				{data.mdx.body}
      </MDXRenderer>
      {!!data.mdx.frontmatter.date ? <div className="italic">This page was last updated on {data.mdx.frontmatter.date}.</div> : ''}
    </Layout>
  )
}

export const q = graphql`
query pageQuery($id: String!) {
  mdx(id: {eq: $id}) {
    id
    body
    frontmatter {
      title
      date (formatString: "D MMMM YYYY")
    }
  }
}`

export default Page